import React, { createContext, useContext, useState, useEffect } from "react";

const PlanContext = createContext<{
  hasPolicies: boolean;
  updateHasPolicies: (value: boolean) => void;
}>({
  hasPolicies: false,
  updateHasPolicies: () => {},
});

export const PlanProvider = ({ children }: { children: React.ReactNode }) => {
  const [hasPolicies, setHasPolicies] = useState<boolean>(false);

  const checkIfPolicyPurchased = () => {
    const hasPolicies = localStorage.getItem("hasPolicies");
    setHasPolicies(!!hasPolicies);
  };

  const updateHasPolicies = (value: boolean) => {
    setHasPolicies(value);
    localStorage.setItem("hasPolicies", value.toString());
  };

  useEffect(() => {
    checkIfPolicyPurchased();
  }, []);

  return (
    <PlanContext.Provider value={{ hasPolicies, updateHasPolicies }}>
      {children}
    </PlanContext.Provider>
  );
};

export const usePlan = () => useContext(PlanContext);

import React from "react";
import { Typography, Row, Col } from "antd";
import ActionTile from "../components/common/Actions/ActionTile";
import "../styles/_payswift.scss";
import { actions, people } from "../const/payswift";

const PaySwiftHome = () => {
  return (
    <div className="payswift-home">
      <div className="content">
        <Row gutter={[16, 16]}>
          {actions.map((action, index) => (
            <Col span={6} key={index}>
              <ActionTile
                icon={<img src={action.icon} alt={action.title} />}
                title={action.title}
                titleProps={{
                  className: "title center min-2",
                  align: "center",
                }}
                iconProps={{
                  className: "icon fixd-25",
                  align: "center",
                }}
              />
            </Col>
          ))}
        </Row>
        <Typography.Title level={5} className="section-title">
          People
        </Typography.Title>
        <Row gutter={[16, 16]}>
          {people.map((person, index) => (
            <Col span={6} key={index}>
              <ActionTile
                icon={<img src={person.image} alt={person.name} />}
                title={person.name}
                outerProps={{ noIconBorder: true }}
              />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default PaySwiftHome;

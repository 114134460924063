import React from "react";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import BackStrip from "../components/header/BackStrip";
import { useLocation } from "react-router-dom";
import PaySwift from "../assets/payswift/PaySwift.svg";

const Layout: React.FC = (props: any) => {
  const location = useLocation();

  const getAppDetails = () => {
    if (location.pathname.includes("/")) {
      return {
        title: "Home",
        link: "/",
        section: location.pathname.split("/")[1],
      };
    }
    return {
      title: "+",
      link: "/",
      section: location.pathname.split("/")[1],
    };
  };

  console.log(getAppDetails());

  return (
    <div
      className={`layout ${location?.pathname === "/cart" ? "bg-gray" : ""}`}
    >
      {/* {location.pathname !== "/" && (
        <BackStrip title={"Payswift"} link={getAppDetails()?.link} />
      )} */}
      <Header
        title={getAppDetails()?.title}
        section={getAppDetails()?.section}
        logo={PaySwift}
      />
      {props.children}
      <Footer />
    </div>
  );
};

export default Layout;

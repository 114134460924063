import React from "react";
import { Route, Routes } from "react-router-dom";
import Landing from "../pages/Landing";
import Components from "../pages/Components";
import PaySwiftHome from "../pages/PaySwiftHome";
import PlanSelection from "../pages/payswift/SelectPlan";
import InsurancePlus from "../pages/payswift/InsurancePlus";
import FillDetails from "../pages/payswift/FillDetails";
import Cart from "../pages/payswift/Cart";
import PaySuccess from "../pages/payswift/PaySuccess";
import MyPolicies from "../pages/payswift/MyPolicies";
import PaymentMethods from "../pages/Payment";

const Router: React.FC = () => {
  return (
    <Routes>
      <Route path="/welcome" element={<Landing />} />
      <Route path="/" element={<PaySwiftHome />} />

      <Route path="*" element={<PaySwiftHome />} />
      <Route path="select-plan" element={<PlanSelection />} />
      <Route path="insurance" element={<InsurancePlus />} />
      <Route path="fill-details" element={<FillDetails />} />
      <Route path="cart" element={<Cart />} />
      <Route path="payment" element={<PaymentMethods />} />
      <Route path="pay-success" element={<PaySuccess />} />
      <Route path="my-policies" element={<MyPolicies />} />

      {/* Components */}
      <Route path="/components/usage" element={<Components />} />
    </Routes>
  );
};

export default Router;

import React from "react";
import { Layout, Card } from "antd";
import "./_payswift.scss";
import { insuranceTypes } from "../../const/payswift";
import ActionTile from "../../components/common/Actions/ActionTile";
import CarInsurance from "../../assets/payswift/car-insurance.png";
import { useNavigate } from "react-router-dom";

const { Content } = Layout;

const InsurancePlus = () => {
  const navigate = useNavigate();

  const renderContent = () => (
    <Content className="content">
      <h2>Our Protection</h2>
      <div className="insurance-grid">
        {insuranceTypes.map((type) => (
          <Card
            className={
              type.title === "Health Insurance" ? "card pointer" : "card"
            }
            key={type.title}
            onClick={() => {
              if (type.title === "Health Insurance") {
                navigate("/select-plan", { replace: true });
              }
            }}
          >
            <ActionTile
              icon={<img src={type.icon} alt={type.title} />}
              title={type.title}
              outerProps={{ noIconBorder: true, padding: "1px" }}
              iconProps={{ align: "left" }}
              titleProps={{ align: "left" }}
            />
          </Card>
        ))}
      </div>
      <div className="car-insurance-banner">
        <img src={CarInsurance} alt="Car Insurance" />
      </div>
    </Content>
  );

  return <div className="insurance-page">{renderContent()}</div>;
};

export default InsurancePlus;

import qrCodeIcon from "../../assets/payswift/ri_qr-code-line.svg";
import contactsIcon from "../../assets/payswift/ri_contacts-book-2-line.svg";
import loanIcon from "../../assets/payswift/Group.svg";
import upiIcon from "../../assets/payswift/UPI-Logo-vector 1.svg";
import selfTransferIcon from "../../assets/payswift/ri_user-received-line.svg";
import bankTransferIcon from "../../assets/payswift/ri_bank-line.svg";
import rewardsIcon from "../../assets/payswift/ri_vip-crown-2-line.svg";
import phoneIcon from "../../assets/payswift/ri_smartphone-line.svg";
import anupamImage from "../../assets/payswift/Ellipse-1096.svg";
import amanImage from "../../assets/payswift/Ellipse-1096-1.svg";
import kanchanImage from "../../assets/payswift/Ellipse-1096-2.svg";
import shwetaImage from "../../assets/payswift/Ellipse-1096-3.svg";
import anupam2Image from "../../assets/payswift/Ellipse-1096-4.svg";
import aman2Image from "../../assets/payswift/Ellipse-1096-5.svg";
import kiwiImage from "../../assets/payswift/Ellipse-1096-6.svg";
import sanjayImage from "../../assets/payswift/Ellipse-1096-7.svg";
import hinsuranceIcon from "../../assets/payswift/hinsurance.svg";
import mdiCreditCardOutline from "../../assets/payswift/mdi_credit-card-outline.svg";
import bxSupport from "../../assets/payswift/bx_support.svg";
import mdiCogOutline from "../../assets/payswift/mdi_cog-outline.svg";
import mdiBellOutline from "../../assets/payswift/mdi_bell-outline.svg";
import healthIcon from "../../assets/payswift/healthicons_health-outline.svg";
import fluentVehicleCarProfile20Regular from "../../assets/payswift/fluent_vehicle-car-profile-20-regular.svg";
import iconoirShop from "../../assets/payswift/iconoir_shop.svg";
import letsIconsCriticalLight from "../../assets/payswift/lets-icons_critical-light.svg";
import fluentVehicleCarProfile20Regular3 from "../../assets/payswift/fluent_vehicle-car-profile-20-regular-3.svg";
import cyberSecurity1 from "../../assets/payswift/cyber-security-1.svg";
import carbonHome from "../../assets/payswift/carbon_home.svg";
import fluentVehicleCarProfile20Regular1 from "../../assets/payswift/fluent_vehicle-car-profile-20-regular-1.svg";
import lightTravelLuggageAndBagsOutline from "../../assets/payswift/light_travel-luggage-and-bags-outline.svg";

export const insuranceTypes = [
  { title: "Health Insurance", icon: hinsuranceIcon },
  { title: "Life Insurance", icon: fluentVehicleCarProfile20Regular },
  { title: "Travel Insurance", icon: lightTravelLuggageAndBagsOutline },
  { title: "Motor Insurance", icon: fluentVehicleCarProfile20Regular1 },
  { title: "Home Insurance", icon: carbonHome },
  { title: "Cyber Insurance", icon: cyberSecurity1 },
  { title: "Accident Insurance", icon: fluentVehicleCarProfile20Regular3 },
  { title: "Critical Illness", icon: letsIconsCriticalLight },
  { title: "Shop Insurance", icon: iconoirShop },
];

export const sidebarValues = [
  { key: "0", label: "My Policies", icon: healthIcon, isClickable: true },
  { key: "1", label: "Payment", icon: mdiCreditCardOutline, isClickable: false },
  { key: "2", label: "Refer & Earn", icon: mdiBellOutline, isClickable: false },
  { key: "3", label: "Insurance", icon: healthIcon, isClickable: true },
  { key: "4", label: "Notifications", icon: mdiBellOutline, isClickable: false },
  { key: "5", label: "Settings", icon: mdiCogOutline, isClickable: false },
  { key: "6", label: "Support", icon: bxSupport, isClickable: false },
];

export const actions = [
  { icon: qrCodeIcon, title: "Scan QR" },
  { icon: contactsIcon, title: "Pay contacts" },
  { icon: loanIcon, title: "Loan" },
  { icon: upiIcon, title: "Pay with UPI" },
  { icon: selfTransferIcon, title: "Self transfer" },
  { icon: bankTransferIcon, title: "Bank transfer" },
  { icon: rewardsIcon, title: "My Rewards" },
  { icon: phoneIcon, title: "Pay phone number" },
];

export const people = [
  { name: "Anupam", image: anupamImage },
  { name: "Aman", image: amanImage },
  { name: "Kanchan", image: kanchanImage },
  { name: "Shweta", image: shwetaImage },
  { name: "Anupam", image: anupam2Image },
  { name: "Aman", image: aman2Image },
  { name: "Kiwi", image: kiwiImage },
  { name: "Sanjay", image: sanjayImage },
];

export const inclusionsSet = [
  "Day care treatments",
  "Pre/Post hospitalisation",
  "Initial waiting period",
  "Covid-19 coverage",
  "Alternate treatments",
  "Organ donor expenses",
  "Emergency road ambulance",
  "Internal Congenital",
  "Lasik Surgery",
];

export const exclusionsSet = [
  "OPD treatment",
  "Home hospitalisation",
  "Psychiatric Illness",
  "Cochlear Impant",
  "Infertility Treatments",
  "Cosmetic/Plastic surgery",
];

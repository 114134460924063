import React, { useMemo } from "react";
import { Typography } from "antd";
import { EditOutlined } from "@ant-design/icons";
import "./_payswift.scss";
import { useNavigate } from "react-router-dom";
import ProductCard from "../../components/cards/ProductCard";
import { usePlan } from "../../context/PlanProvider";

const { Title, Text, Link } = Typography;

const Cart = () => {
  const navigate = useNavigate();
  const { updateHasPolicies } = usePlan();

  const onSubmit = () => {
    updateHasPolicies(true);
    localStorage.setItem("premium", premium?.total?.toString());
    navigate("/payment", { replace: true });
  };
  const data = JSON.parse(localStorage.getItem("submittedDetails") ?? "{}");
  const premium = useMemo(() => {
    let x = 1500;
    if (data?.spouseDetails?.length) x = 1800;
    if (data?.childDetails?.length) x = x + 200 * data?.childDetails?.length;
    return {
      spouse: data?.spouseDetails?.length,
      children: data?.childDetails?.length,
      total: x * 12,
    };
  }, [data]);

  return (
    <div className="cart-page">
      <div className="selected-plan">
        <Title level={4} className="added-heading">
          Product Added
        </Title>
        <ProductCard premium={premium?.total} />
      </div>

      <div className="covered-members">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="covered-heading">Covered Members</div>
          <Link className="edit-link">
            <EditOutlined color="#0856DF" /> Edit
          </Link>
        </div>
        <Text className="members-heading">Self{premium?.spouse ? ", Spouse" : ""}{premium?.children ? `, ${premium?.children} Children` : ""}</Text>
      </div>

      <div className="total-section">
        <Text className="price">
        ₹{premium?.total}<span className="gst">/yr + GST</span>
        </Text>
        <button onClick={onSubmit} className="proceed-button">
          Proceed
        </button>
      </div>
    </div>
  );
};

export default Cart;

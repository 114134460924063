import React from "react";
import { Row, Col, Image, Typography } from "antd";
import "./_listing.scss";

const { Title, Text } = Typography;

interface ImageProps {
  width?: number;
  height?: number;
}

interface ShortProductType {
  name: string;
  price: string;
  brand: string;
  img?: string;
  imgProps?: ImageProps;
  onClick?: () => void;
}

const ProductListBox = ({
  name,
  price,
  brand,
  img,
  imgProps,
  onClick,
}: ShortProductType) => {
  return (
    <Row
      className="product-list-box"
      align="middle"
      justify="start"
      gutter={[16, 16]}
      wrap={false}
      onClick={onClick}
    >
      <Col className="product-list-img" xs={6}>
        <Image
          alt="product_image"
          src={img}
          width={imgProps?.width}
          height={imgProps?.height}
        />
      </Col>
      <Col className="product-list-details" xs={20}>
        <Title level={3} className="product-list-text name">
          {name}
        </Title>
        <Text className="product-list-brand">BRAND : {brand}</Text>
        <Text className="product-list-text">₹{price}</Text>
      </Col>
    </Row>
  );
};

export default ProductListBox;
